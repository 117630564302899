import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import CommonButton from "../CommonButton";
import { updateCartData, findProductInCart } from "../../../cart";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../actions/snackbar";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import RemoveShoppingCartIcon from "@material-ui/icons/RemoveShoppingCart";
import { CART_UPDATED } from "../../../constants/actionTypes";
import useStyles from "./styles";
import { useLocation } from "react-router-dom";
import { redirect_path_after_login } from "../../../helpers/common";

function AddToCartButton({
  cartData,
  product,
  navigate,
  customer,
  quantity = 1,
  fullWidth = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {pathname} = useLocation()

  const addProductToCart = useCallback(
    (product) => {
      const minimumOrderQuantity = product?.minimum_order_quantity;
      const maximumOrderQuantity = product?.maximum_order_quantity;

      if (quantity > maximumOrderQuantity) {
        dispatch(
          showErrorSnackbar(
            `You can not order more than ${maximumOrderQuantity} item(s)`,
            null
          )
        );
        return;
      }

      if (minimumOrderQuantity > quantity) {
        dispatch(
          showErrorSnackbar(
            `You must order at least ${minimumOrderQuantity} item(s)`,
            null
          )
        );
        return;
      }

      const newCartData = updateCartData(cartData, product, quantity);

      dispatch({ type: CART_UPDATED, payload: newCartData });

      dispatch(showSuccessSnackbar("product is added to cart"));
    },
    [dispatch, cartData, quantity]
  );

  // const removeProductFromCart = useCallback((product) => {

  //     const newCartData = updateCartData(cartData, product, 0);

  //     dispatch({ type: CART_UPDATED, payload: newCartData });

  //     dispatch(showErrorSnackbar("product is removed from cart"));

  // }, [dispatch, cartData]);

  const goToCart = (e) => {
    e.preventDefault();
    if (customer?.mobile_number) {
      navigate("/cart");
    } else {
      dispatch(showErrorSnackbar("You need to login first"));
      navigate("/login");
      localStorage.setItem(redirect_path_after_login, pathname)
    }
  };

  if (product?.available_stock <= 0 || product?.selling_price === 0) {
    return (
      <CommonButton
        rounded
        disabled
        style={{
          borderRadius: 28,
          color: "black",
          width: fullWidth ? "100%" : null,
        }}
        className={classes.commonButton}
        startIcon={<RemoveShoppingCartIcon />}
      >
        OUT OF STOCK{" "}
      </CommonButton>
    );
  }

  if (findProductInCart(cartData, product)) {
    return (
      <CommonButton
        rounded
        style={{
          borderRadius: 28,
          minWidth: 190,
          backgroundColor: "#1eab5c",
          width: fullWidth ? "100%" : null,
        }}
        className={classes.commonButton}
        onClick={goToCart}
        endIcon={<ArrowForwardIcon />}
      >
        Buy Now
      </CommonButton>
    );
  }

  return (
    <CommonButton
      rounded
      style={{ borderRadius: 28, width: fullWidth ? "100%" : null }}
      onClick={(e) => {
        e.preventDefault();
        addProductToCart(product);
      }}
      className={classes.commonButton}
      startIcon={<ShoppingCartIcon />}
    >
      Add to Cart
    </CommonButton>
  );
}

export default AddToCartButton;
