import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  Grow,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/Person";
import PhoneEnabledOutlined from "@material-ui/icons/PhoneEnabledOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import classnames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getMenus } from "../../../actions/menus";
import { LOAD_CART_DATA } from "../../../constants/actionTypes";
import { imageUrl } from "../../../helpers/common";
import useStyles from "./styles";

const WebHeader = ({onLogout, searchKeyword, onSearchInput, setSearchKeyword}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const navMenus = useSelector((state) => state.menus.topMenu);
  const [searchCollapsed, setSearchCollapsed] = useState(true);
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const profile = useSelector((state) => state.auth.profile);
  const assets = useSelector((state) => state.assets.data);
  const frontendData = useSelector((state) => state.contents.frontendData);
  const contacts = frontendData
    .find((data) => data.key === "company_contact_number")
    ?.value.trim()
    .split("\n");

  const cartState = useSelector((state) => state.cart);
  const cartQty = cartState.data.length;

  const logo = assets?.find((data) => data?.name === "Logo")

  useEffect(() => {
    dispatch(getMenus("top-menu"));
  }, [dispatch]);

  useEffect(() => {
    dispatch({ type: LOAD_CART_DATA });
  }, [dispatch]);


  return (
    <div className={classes.webHeader}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar + " " + classes.appContainer}>
          {!downMd && (
            <Box display="flex">
              <Box
                style={{
                  border: "1px solid #000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "35px",
                  height: "35px",
                  borderRadius: "50%",
                }}
              >
                <PhoneEnabledOutlined style={{ fontSize: 21, color: "#000" }} />
              </Box>

              {contacts && <Button variant="contained" className={classes.whiteButton}>
                <a href={`${contacts[0]}`} target="_blank" rel="noreferrer" >Contact Now</a>
              </Button>}
            </Box>
          )}
          <Box justifyContent="center" textAlign="center" flexGrow={1}>
            {searchCollapsed && (
              <Link to="/" className={classes.bannerLink}>
                <img
                  src={`${imageUrl(logo?.file_name, 'assets', '')}`}
                  height={downMd ? "80" : "80"}
                  alt="HomeShop Fashion Logo"
                />
              </Link>
            )}

            {(downMd || !searchCollapsed) && (
              <Grow in={downMd || !searchCollapsed} timeout={500}>
                <Paper elevation={4} className={classes.searchInput}>
                  <IconButton style={{ padding: 2 }}>
                    <SearchOutlinedIcon color="secondary" />
                  </IconButton>
                  <InputBase
                    placeholder="Search product here"
                    value={searchKeyword}
                    onChange={onSearchInput}
                    onKeyDown={onSearchInput}
                    defaultValue={searchKeyword}
                    onKeyUp={(e) => setSearchKeyword(e.target.value)}
                  />
                  <IconButton
                    style={{ padding: 2 }}
                    onClick={() => {
                      setSearchCollapsed(true);
                      setSearchKeyword("");
                    }}
                  >
                    <CloseIcon color="secondary" />
                  </IconButton>
                </Paper>
              </Grow>
            )}
          </Box>

          <Box display="flex" className={classes.box}>
            {!downMd && searchCollapsed && (
              <>
                <IconButton
                  style={{
                    color: "#fff",
                    border: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                  }}
                  onClick={() => setSearchCollapsed(false)}
                >
                  <SearchOutlinedIcon style={{ color: "#000", fontSize: 20 }} />
                </IconButton>

                {profile.mobile_number && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ backgroundColor: "#fff" }}
                  />
                )}
              </>
            )}
            {profile.mobile_number && (
              <>
                <IconButton
                  style={{
                    color: "#fff",
                    border: "1px solid #000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                  }}
                  onClick={() => navigate("/cart")}
                >
                  <Badge
                    badgeContent={cartQty}
                    showZero={true}
                    classes={{ badge: classes.badge }}
                  >
                    <ShoppingCartOutlinedIcon
                      style={{ color: "#000", fontSize: 20 }}
                    />
                  </Badge>
                </IconButton>
              </>
            )}

            {profile.mobile_number && profile.verified ? (
              <Button
                aria-controls="customized-menu"
                edge="end"
                aria-haspopup="true"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
              >
                <Avatar className={classes.avatar} data-testid="header-avatar-test-id">
                  <AccountCircleIcon />
                </Avatar>
                <Typography className={classes.email}>
                  {profile.name ? profile.name : "User"}
                </Typography>
                <KeyboardArrowDownIcon className={classes.icon} />
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => navigate("/login")}
                className={classes.whiteButton}
              >
                Login / Register
              </Button>
            )}
          </Box>
        </Toolbar>

        <Toolbar className={classes.navMenu}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={classes.expandedMenu}
          >
            {navMenus?.map((menu, index) => (
              <Fragment key={menu.id}>
                <Link to={menu.url} className={classes.linkMenu}>
                  <Typography variant="body2" className={classes.navItems}>
                    {menu.name}
                  </Typography>
                </Link>
                {/* {!(navMenus?.length === index + 1) && (
                  <Divider orientation="vertical" flexItem />
                )} */}
              </Fragment>
            ))}
          </Box>
          <IconButton
            edge="start"
            className={classes.menuButton + " " + classes.menuIconButton}
            aria-label="menu"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <MenuIcon /> : <CloseIcon />}
          </IconButton>
        </Toolbar>
        <Collapse in={!collapsed} className={classes.menuButton}>
          <Paper elevation={0} square>
            {navMenus?.map((menu, index) => (
              <Fragment key={menu.id}>
                <Link
                  to={menu.url}
                  className={classnames(classes.verticalNavItems, classes.link)}
                >
                  <Typography
                    variant="body2"
                    className={`${classes.navItems} ${downMd ? classes.mobNavItems : null
                      }`}
                  >
                    {menu.name}
                  </Typography>
                </Link>
                {!(navMenus?.length === index + 1) && (
                  <Divider variant="middle" />
                )}
              </Fragment>
            ))}
          </Paper>
        </Collapse>
      </AppBar>

      {profile.mobile_number && profile.verified && (
        <Menu
          id="customized-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: "right" }}
          anchorPosition={{ top: 200, left: 400 }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: "right" }}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <Link to="/profile" className={classes.link}>
              My Profile
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/my-orders" className={classes.link}>
              My Orders
            </Link>
          </MenuItem>
          <MenuItem className={classes.link} onClick={onLogout}>
            Log Out
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default WebHeader;
