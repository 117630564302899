import { Box, IconButton, InputAdornment, TextField } from "@material-ui/core";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { imageUrl } from "../../helpers/common";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import CommonButton from "../Common/CommonButton";
import { showErrorSnackbar } from "../../actions/snackbar";
import { updateCartData } from "../../cart";
import { updateCart } from "../../actions/cart";

const SingleCartItem = ({ product, removeItem, products }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const cartProductQty = product.qty || 1;
  const dispatch = useDispatch();

  const [quantity, setQuantity] = useState(1);

  const updateQuantity = () => {
    const minimumOrderQuantity = product?.minimum_order_quantity;
    const maximumOrderQuantity = product?.maximum_order_quantity;

    if (quantity > maximumOrderQuantity) {
      dispatch(
        showErrorSnackbar(
          `You can not order more than ${maximumOrderQuantity} item(s)`,
          null
        )
      );
      return;
    }

    if (minimumOrderQuantity > quantity) {
      dispatch(
        showErrorSnackbar(
          `You must order at least ${minimumOrderQuantity} item(s)`,
          null
        )
      );
      return;
    }

    const newCartData = updateCartData(products, product, quantity);
    dispatch(updateCart(newCartData));
  };

  useEffect(() => {
    setQuantity(cartProductQty);
  }, [cartProductQty]);

  return (
    <Box
      key={product.id}
      my={3}
      p={3}
      display='flex'
      className={classes.cartItemContainer}
    >
      <Box display='flex' alignItems='center' className={classes.left}>
        <Box
          className={classes.itemImg}
          borderRadius={5}
          border='1px solid transparent'
          onClick={() =>
            navigate(`/product/${product.id}/1/${product.url_slug}`)
          }
        >
          <img
            className={classes.images}
            src={imageUrl(
              product.main_image,
              "products/" + product.id,
              "100x120-"
            )}
            alt='cart-item'
          />
        </Box>

        <Box flex={1} ml={2}>
          <Box
            className={classes.itemText}
            fontSize={24}
            fontWeight={700}
            onClick={() =>
              navigate(`/product/${product.id}/1/${product.url_slug}`)
            }
          >
            {product.name} {product?.id}
          </Box>
          <Box
            display='flex'
            alignItems='center'
            gridGap={10}
            color='#4f4f4f'
            flexWrap="wrap"
            mt={1}
            mb={1}
          >
            <Box className={classes.pill}>Size: {product?.item_size}</Box>
            <Box className={classes.pill}>Color: {product?.item_color}</Box>
          </Box>

          <Box className={classes.priceText} display='flex' color='#666' ml={0.6}>
            <Box mr={1}>Price</Box>
            <Box mr={1} style={{ textDecoration: "line-through" }}>
              &#x20b9;{product.mrp}
            </Box>
            <Box>&#x20b9;{product.selling_price}</Box>
          </Box>
          
        </Box>
      </Box>
      <Box>
        <Box display='flex' alignItems='center'>
          <Box>
            <TextField
              label='Quantity'
              variant='outlined'
              value={quantity}
              type='number'
              onChange={(e) => setQuantity(e.target.value)}
              className={classes.qtyInput}
              style={{ marginRight: 12 }}
              inputProps={{ min: 1, max: parseInt(product.sku ?? 0, 10) }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>Qty:</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box fontSize={24} mx={3} color='#666'>
            &#x20b9;
            {product.selling_price * (product.qty ? product.qty : 1)}
          </Box>

          <IconButton onClick={() => removeItem(product)}>
            <HighlightOffOutlinedIcon />
          </IconButton>
        </Box>

        {cartProductQty !== quantity && quantity > 0 && (
          <div className={classes.updateBtn}>
            <CommonButton
              rounded
              width={180}
              loading={false}
              onClick={updateQuantity}
            >
              Update
            </CommonButton>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default SingleCartItem;
