import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AccountCircleIcon from "@material-ui/icons/Person";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { imageUrl } from "../../../helpers/common";
import MobileMenuDrawer from "./MobileMenuDrawer";
import useStyles from "./mobileStyles";

const MobileHeader = ({
  onLogout,
  searchKeyword,
  onSearchInput,
  setSearchKeyword,
}) => {
  const classes = useStyles();
  const assets = useSelector((state) => state.assets.data);
  const logo = assets?.find((data) => data?.name === "Logo");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const cartState = useSelector((state) => state.cart);
  const cartQty = cartState.data.length;

  const profile = useSelector((state) => state.auth.profile);

  return (
    <div className={classes.mobileHeader}>
      <div className={classes.appBar}>
        <Box>
          <Link to='/' className={classes.logo}>
            <img
              src={`${imageUrl(logo?.file_name, "assets", "")}`}
              alt='HomeShop Fashion Logo'
            />
          </Link>
        </Box>

        <Box display='flex' alignItems='center' gridGap={5}>
          <IconButton
            style={{
              color: "#fff",
              border: "1px solid #000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "35px",
              height: "35px",
              borderRadius: "50%",
            }}
            onClick={() => navigate("/cart")}
          >
            <Badge
              badgeContent={cartQty}
              showZero={true}
              classes={{ badge: classes.badge }}
            >
              <ShoppingCartOutlinedIcon
                style={{ color: "#000", fontSize: 20 }}
              />
            </Badge>
          </IconButton>

          <Button
            aria-controls='customized-menu'
            edge='end'
            aria-haspopup='true'
            onClick={(event) => setAnchorEl(event.currentTarget)}
            color='inherit'
          >
            <Avatar
              className={classes.avatar}
              data-testid='header-avatar-test-id'
            >
              <AccountCircleIcon />
            </Avatar>
          </Button>

          <MobileMenuDrawer logo={logo} />
        </Box>
      </div>

      {/* Account Dropdown */}
      <Menu
        id='customized-menu'
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        anchorPosition={{ top: 200, left: 400 }}
        keepMounted
        transformOrigin={{ vertical: -60, horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {profile.mobile_number && profile.verified ? (
          <>
            <MenuItem>
              <Link
                to='/profile'
                className={classes.link}
                onClick={() => setAnchorEl(null)}
              >
                My Profile
              </Link>
            </MenuItem>
            <MenuItem>
              <Link
                to='/my-orders'
                className={classes.link}
                onClick={() => setAnchorEl(null)}
              >
                My Orders
              </Link>
            </MenuItem>
            <MenuItem
              className={classes.link}
              onClick={() => {
                onLogout();
                setAnchorEl(null);
              }}
            >
              Log Out
            </MenuItem>
          </>
        ) : (
          <MenuItem
            className={classes.link}
            onClick={() => {
              navigate("/login");
              setAnchorEl(null);
            }}
          >
            Login / Register
          </MenuItem>
        )}
      </Menu>

      {/* Search bar */}
      <Paper className={classes.searchInput}>
        <IconButton style={{ padding: 2 }}>
          <SearchOutlinedIcon color='primary' />
        </IconButton>
        <InputBase
          placeholder='Search product here'
          value={searchKeyword}
          onChange={onSearchInput}
          onKeyDown={onSearchInput}
          defaultValue={searchKeyword}
          onKeyUp={(e) => setSearchKeyword(e.target.value)}
          className={classes.input}
        />
        {searchKeyword && <IconButton style={{ padding: 2 }} onClick={() => setSearchKeyword("")}>
          <CloseIcon color='primary' />
        </IconButton>}
      </Paper>
    </div>
  );
};

export default MobileHeader;
