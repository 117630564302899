import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  Divider,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateProfile } from "../../../actions/customer";
import { getOrderDetails } from "../../../actions/orders";
import { convertToSlug, imageUrl } from "../../../helpers/common";
import CommonButton from "../../Common/CommonButton";
import { showErrorSnackbar } from "../../../actions/snackbar";
import { LOAD_CART_DATA } from "../../../constants/actionTypes";
import Helper from "../Helper";
import MetaTags from "../../Common/MetaTags";

export default function OrderDetails() {
  const { orderId } = useParams();
  const currentOrder = useSelector((state) => state.orders.currOrder);
  const cartData = useSelector((state) => state.cart.data);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId]);

  const onReorder = (e) => {
    e.preventDefault();


    const productList = Helper.getFinalProductList(cartData, currentOrder);

    localStorage.setItem("hsCartProducts", JSON.stringify(productList));
    setLoading(true);

    dispatch(updateProfile({ cart_data: JSON.stringify(productList) })).then(
      (ack) => {
        setLoading(false);
        dispatch({ type: LOAD_CART_DATA });
        if (ack) navigate("/cart");
        else dispatch(showErrorSnackbar("Sorry! Products cannot be added!"));
      }
    );
  };

  return (
    <Box p={3}>
      <MetaTags title='Order Details' description='' keywords=''></MetaTags>

      <Box m='auto' maxWidth={1200}>
        <Box fontSize={20} color='#666'>
          Order Details
          <div style={{ float: 'right' }}>
            {/* <div>Order Date: <strong>{orderId}</strong></div> */}
            <div>Order ID: <strong>{orderId}</strong></div>
          </div>
        </Box>
        <List>
          {currentOrder.map(({ id, product, total_amount, quantity }) => {
            return (
              <>
                <ListItem
                  alignItems='flex-start'
                  onClick={() =>
                    navigate(`/product/${product?.id}/1/${convertToSlug(product?.name)}`)
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      variant='rounded'
                      alt={product?.name}
                      src={imageUrl(product?.main_image, 'products/' + product.id, '100x120-')}
                      style={{ width: 75, height: 75, marginRight: 20 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box fontWeight={700} fontSize={20}>
                        {product?.name} {product?.id}
                      </Box>
                    }
                    secondary={
                      <Box display='flex' fontSize={16}>
                        <Box>
                          <Box component='span'>Price : </Box>
                          <Typography
                            component='span'
                            variant='body2'
                            color='textPrimary'
                            style={{ textDecoration: "line-through" }}
                          >
                            {`Rs. ${product?.mrp} `}
                          </Typography>
                          <Typography
                            component='span'
                            variant='body2'
                            color='textPrimary'
                          >
                            {` Rs. ${product?.selling_price}`}
                          </Typography>

                          <Box>{`Quantity : ${quantity}`}</Box>
                        </Box>
                        <Box
                          ml='auto'
                          fontSize={18}
                        >{`Total Price : Rs. ${total_amount}`}</Box>
                      </Box>
                    }
                  />
                </ListItem>
                <Divider />
              </>
            )
          })}
        </List>

        <Box display='flex' justifyContent='flex-end'>
          <CommonButton
            rounded
            loading={loading}
            width={150}
            onClick={onReorder}
          >
            Reorder
          </CommonButton>
        </Box>
      </Box>
    </Box>
  );
}
