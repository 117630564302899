import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  detailsMainWrapper: {
    overflow: 'hidden',
    marginTop: 32,
    [theme.breakpoints.down("sm")]: {
      marginTop: -20,
    },
  },
  chip: {
    margin: "10px 3px",
    padding: 15,
    fontSize: 16,
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      width: "98%",
    },
  },
  commonButton: {
    width: 195,
    height: 53,
    borderRadius: 38,
    background: "#E16434",
    color: "#fff",
    "@media (max-width: 380px)": {
      width: 150,
      fontSize: 13,
    },
  },
  mainImgBox: {
    width: 416,
    height: 480,
    "@media (max-width: 450px)": {
      width: "100%",
      height: "auto",
    },
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  ratingIcon: {
    color: "#E16434",
  },
  skeleton: {
    marginBottom: 12,
  },
  colorPrimary: {
    width: 120,
    background: "#E16434",
  },
  writeReview: {
    paddingLeft: 20,
  },
  ratingContainer: {
    borderRadius: 8,
    padding: 20,
    background: "#fff",
    border: "1px solid #aaa",
  },
  reviewForm: {
    marginTop: 24,
    marginRight: 24,
    "& > *": {
      marginTop: 16,
    },
    marginBottom: 15,
  },
  zipInput: {
    "& > .MuiInputBase-root": {
      borderRadius: 30,
      backgroundColor: "#eee",
      fontFamily: "Product Sans",
      fontWeight: 700,
    },
  },
  gridList: {
    marginTop: 20,
    flexWrap: "nowrap",
  },
  gridListTile: {
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    border: "2px solid transparent !important",
    marginLeft: 4,
    marginBottom: 4,
    "&:hover": {
      border: "2px solid #E16434 !important",
    },
  },
  additional_fields_text: {
    fontSize: 18,
    marginTop: 8,
  },
  colorSec: {
    display: "flex",
    marginBottom: 15,
    flexWrap: "wrap",
    gap: 10
  },
  similarProductsSec: {
    marginTop: 30
  },
  link: { textDecoration: "none", color: "inherit" },
  colorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "1px solid #aaa",
    textAlign: "center",
    borderRadius: '50%',
    width: 60,
    height: 60,
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    '& > img': {
      height: '52px !important',
      width: '52px !important',
    },
    "&:hover": {
      background: "#E16434",
      transition: "all 0.3s ease-in-out",
      border: "2px solid #E16434 !important",
    },
  },
  sizeSec: {
    display: "flex",
    flexWrap: "wrap",
  },
  sizeBox: {
    border: "1px solid #aaa",
    marginTop: 10,
    marginRight: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 45,
    height: 45,
    borderRadius: '50%',
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      background: "#E16434",
      color: "#fff",
      transition: "all 0.3s ease-in-out",
    },
  },
  activeColor: {
    border: "2px solid #E16434 !important",
    background: "#E16434",
  },
  activeSize: {
    border: "2px solid #E16434 !important",
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 0,
    "@media (max-width: 500px)": {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      zIndex: 10000,
      backgroundColor: '#fff',
      left: 0,
      right: 0,
      padding: '8px 15px',
    },
  }
}));

export default useStyles;
