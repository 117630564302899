import { LOGOUT_SUCCESS, CART_UPDATED, CLEAR_CART, LOAD_CART_DATA } from '../constants/actionTypes';

const defaultState = {
    data: [],
};

export default function cart(state = defaultState, action) {
    switch (action.type) {
        case CART_UPDATED:
            localStorage.setItem('hsCartProducts', JSON.stringify(action.payload));
            return { ...state, data: action.payload };
        case LOAD_CART_DATA:
            let data = localStorage.getItem('hsCartProducts') || '[]';
            try {
                return { ...state, data: JSON.parse(data) };
            } catch (e) {
                return { ...state, data: [] };
            }
        case LOGOUT_SUCCESS:
        case CLEAR_CART:
            return { ...state, data: [] };
        default:
            return state;
    }
}
