import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#E16434',
    padding: '8px 15px',
    color: '#fff',
    textTransform: 'capitalize',
    minWidth: 80,
  },
  outlineButton: {
    backgroundColor: 'transparent',
    color: '#000',
    borderColor: '#E16434',
  },  
  wrapper: {
    margin: 0,
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default useStyles;
