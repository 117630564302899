import { Box, CircularProgress, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import ProductBox from "../Common/ProductBox";
import { imageUrl, server_url } from "../../helpers/common";
import axios from "axios";

let loading = false;
let hasMoreProducts = true;

const InfiniteScroller = ({ categoryId, productLabel, getFilterParams }) => {
  const classes = useStyles();
  const perPageItems = 6;

  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const getSearchParams = useCallback(() => {
    let params = [];

    if (categoryId) {
      params.push(
        `ProductsSearch[category_id]=${categoryId}&group_by=item_color`
      );
    }

    if (productLabel) {
      const optionName = {
        "hot-products": "Hot",
        "featured-products": "featured",
      };
      params.push(
        `ProductsSearch[product_labels]=${
          optionName[productLabel] || productLabel
        }`
      );
    }

    params.join("&");

    return `${params}&per-page=${perPageItems}&page=${page}&sort=-id&${getFilterParams()}`;
  }, [categoryId, getFilterParams, page, productLabel]);

  const fetchProducts = useCallback(
    async (page) => {
      if (loading || !page || !hasMoreProducts) {
        return false;
      }

      if (!categoryId && !productLabel) {
        setHasMore(false);
        return false;
      }

      loading = true;

      const res = await axios.get(
        `${server_url}/products?${getSearchParams()}`
      );
      const data = await res.data;

      loading = false;
      setPage(page + 1);

      if (data?.length < perPageItems) {
        hasMoreProducts = false;
      }

      return data;
    },
    [categoryId, getSearchParams, productLabel]
  );

  const fetchData = useCallback(async () => {
    const productFromServer = await fetchProducts(page);
    if (productFromServer) {
      setProducts((loadedProducts) => {
        const hasDuplicate = loadedProducts?.some((oldItem) =>
          productFromServer?.some((newItem) => newItem?.id === oldItem.id)
        );

        if (!hasDuplicate) {
          return [...loadedProducts, ...productFromServer];
        } else {
          setHasMore(false);
          return [...loadedProducts];
        }
      });
    }

    if (
      productFromServer.length === 0 ||
      productFromServer.length < perPageItems
    ) {
      console.log("no more products ");
      setHasMore(false);
    }
  }, [fetchProducts, page]);

  useEffect(() => {
    hasMoreProducts = true;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setProducts([]);
    setPage(1);
    setHasMore(true);
  }, [categoryId, getFilterParams]);

  const notFound = () => {
    return (
      <Box
        minHeight={"70vh"}
        display='flex'
        alignItems={"center"}
        justifyContent='center'
      >
        <Box
          mt={8}
          style={{ marginBottom: 80 }}
          fontSize={32}
          fontWeight={700}
          color='#999'
          textAlign='center'
        >
          No products found
        </Box>
      </Box>
    );
  };

  return (
    <>
      {/* <div
        style={{
          position: "fixed",
          top: 20,
          right: 30,
          background: "red",
          color: "#fff",
          padding: 20,
        }}
      >
        (Duplicate: {findDuplicats()?.toString()}) - (Length: {products?.length}
        ) - (Page: {page})
      </div> */}
      <InfiniteScroll
        pageStart={0}
        loadMore={fetchData}
        hasMore={hasMore}
        loader={
          <Box
            minHeight={"300px"}
            display='flex'
            alignItems={"center"}
            justifyContent='center'
            key={'loader'}
          >
            <CircularProgress />
          </Box>
        }
      >
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems='center'
          spacing={3}
        >
          {products?.map((product) => (
            <Grid key={product.id} item xs={12} sm={4} md={4}>
              <Link
                to={`/product/${product.id}/1/${
                  product.url_slug
                }`}
                className={classes.link}
              >
                <ProductBox
                  image={imageUrl(
                    product.main_image,
                    "products/" + product.id,
                    "600x720-"
                  )}
                  title={product?.name || ""}
                  price={product.mrp}
                  discountedPrice={product.selling_price}
                  product={product}
                />
              </Link>
            </Grid>
          ))}
        </Grid>

        {!hasMore && !products?.length && notFound()}
      </InfiniteScroll>
    </>
  );
};

export default InfiniteScroller;
