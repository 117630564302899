import { Box } from "@material-ui/core";
import React, { useState } from "react";
import DefaultImg from "../../../assets/images/product-default-img.jpg";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddToCartButton from "../AddToCartButton";
import useStyles from "./styles";

const ProductBox = ({ product, image, title, price, discountedPrice }) => {
  const classes = useStyles();
  const cartData = useSelector((state) => state.cart.data);
  const customer = useSelector((state) => state.auth.profile);
  const navigate = useNavigate();

  let priceText = (
    <Box fontSize={22} pl={1}>
      &#x20b9;{discountedPrice}
    </Box>
  );

  if (parseFloat(discountedPrice) > 0) {
    priceText = (
      <React.Fragment>
        <Box
          fontSize={16}
          color='#666'
          style={{ textDecoration: "line-through" }}
        >
          &#x20b9;{price}
        </Box>
        <Box fontSize={22} pl={1}>
          &#x20b9;{discountedPrice}
        </Box>
      </React.Fragment>
    );
  }

  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Box width={"100%"}>
      <Box className={classes.imageBox}>
        <img
          src={image}
          alt={title}
          className={classes.productImage}
          onLoad={handleImageLoad}
        />
        <img
          src={DefaultImg}
          alt='Default placeholder'
          className={`${classes.defaultImage} ${!loading && classes.hidden}`}
        />
      </Box>

      <Box>
        <Box className={classes.contentTop}>
          <Box fontSize={17} className={classes.productBoxTitle}>
            {title}
          </Box>
  
          <Box
            display='flex'
            alignItems='center'
            gridGap={10}
            color='#4f4f4f'
            mt={1}
          >
            <Box className={classes.pill}>Size: {product?.item_size}</Box>
            <Box className={classes.pill}>Color: {product?.item_color}</Box>
          </Box>
        </Box>

        <Box display='flex' alignItems='center'>
          {priceText}
        </Box>

        <Box className={classes.addCartContainer}>
          <AddToCartButton
            cartData={cartData}
            customer={customer}
            product={product}
            navigate={navigate}
            quantity={1}
            fullWidth={true}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ProductBox;
