
export const server_url = process.env.REACT_APP_API_URL;

export const data_url = process.env.REACT_APP_DATA_URL;

export const appUrl = process.env.REACT_APP_URL;
export const appLogoUrl = process.env.REACT_APP_LOGO_URL;
export const appName = process.env.REACT_APP_NAME;

export const capitalize = (str) => `${str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()}`

export const sortFn = ((el1, el2, sortKey = 'display_order') => el1[sortKey] > el2[sortKey] ? 1 : -1);

export const imageUrl = function (imageName, prefix = 'products', sizePrefix = '100x120-') {
  if (sizePrefix === 'original') {
    return `${process.env.REACT_APP_DATA_URL}/${prefix}/${imageName}`; // full size image
  } else {
    return `${process.env.REACT_APP_DATA_URL}/${prefix}/${sizePrefix}${imageName}`;
  }
}

export const validEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// it is used to convert the array into string
export const arrayToStr = (array, seperator = ",", defaultValue = "") => {

  return Array.isArray(array) ? (array?.join(seperator) || defaultValue) : defaultValue;

}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getParsedJson = (json, defaultValue = "[]") => {
  let parsedJson = defaultValue;

  try {
    if (isJsonString(json)) {
      parsedJson = JSON.parse(json ?? defaultValue);
    }

    if (isJsonString(parsedJson)) {
      parsedJson = JSON.parse(parsedJson ?? defaultValue);
    }
  } catch (error) {
    parsedJson = JSON.parse(defaultValue);
  }

  return parsedJson;
};

export const showError = (err) => {
  let error = 'Something went wrong.';

  if (err?.response?.data && err?.response?.data[0]?.message) {
    error = err?.response?.data[0]?.message;
  } else if (err?.response?.data?.message) {
    error = err?.response?.data?.message;
  } else {
    error = err?.message;
  }

  if (error) return error
};

export const getSetting = (key, settings) => {
  return settings.find(setting => setting.key === key);
}

export const truncate = (str, n) =>
  str.length > n ? str.substr(0, n - 1) + '...' : str;

export const convertToSlug = (text) => text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')
// export const convertToSlug = (url_slug) => url_slug;

// to get the product url.
export const productUrl = (product, page = 1) => `/product/${product.id}/${page}/${product.url_slug}`;

export const getCategoryByParentId = (categories, parentId) => categories?.filter(category => parseInt(category.parent_id) === parseInt(parentId));

export const getSettingByKey = (settings, keyName, defaultValue) =>
  settings?.find((setting) => setting.key === keyName) || defaultValue;

export const getCategoryLink = (category) => `/category/${category.id}/1/${category.url_slug}`;

export const getUrlParams = (paramName, paramValue) => {
  // let url = 'https://homeshopfashion.com/category/29/1/shiny-leggings/filter?color=red&size=xl';
  
  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.set(paramName, paramValue);

  // returns => color=red&size=xl
  return urlObj.searchParams.toString();
};

export const removeUrlParam = (paramName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  urlObj.searchParams.delete(paramName);
  return urlObj.searchParams.toString();
};

export const getAppliedFilterByName = (filterName) => {
  let url = document.location;
  var urlObj = new window.URL(url);
  return urlObj.searchParams.get(filterName);
};

export const getFilterPath = (categoryId, categoryName, pageNumber, params) => {
  return `/category/${categoryId}/${pageNumber}/${categoryName}/filters?${params}`;
};

// it is used to make the given string into array by splitting using the given seperator.
export const toArray = (value, seperator = ",") => {
  let arr = [];

  if (value) {
    arr = value
      .split(seperator)
      .filter(Boolean)
      .map((v) => v.trim())
      .filter((v) => v !== "");
  }
  return arr;
};

export const toKeyValues = (string, firstSeperator = "\n", secondSeperator = "=>") => {
  const list = toArray(string, firstSeperator);
  return list.map((section) => {
    const keyVal = toArray(section, secondSeperator);
    const key = keyVal[0];
    const value = keyVal[1] ? keyVal[1] : key;
    return {
      key,
      value,
    };
  });
}

export const customerId_local_storage_key = 'homeshop_customerId';
export const authKey_local_storage_key = 'homeshop_authToken';
export const cartData_local_storage_key = 'homeshop_cart_data';
export const userInfo_local_storage_key = 'homeshop_userInfo';
export const redirect_path_after_login = 'homeshop_redirect_path_after_login';